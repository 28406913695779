export enum RuleId {
  SpendKey = 1,
  KeyEffect,
  BuyCard,
  DiscardFromRiver,
  ChooseBetween,
  MoveMessenger,
  EndOfTurn,
  EndGame
}
